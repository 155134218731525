require('intersection-observer')

exports.onClientEntry = () => {
  window.process = {
    env: process.env
  }
}

exports.onRouteUpdate = () => {
  (function(w,d,s,l,i){
    var g=w[l] ? '&s='+w[l]:'', f=d.getElementsByTagName(s)[0], j=d.createElement(s);
    j.async=true,j.src='https://dntfctn.com/stm.js?id='+i+g;
    j.referrerPolicy='no-referrer-when-downgrade';
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','name','x4H4O8B7P2p3')
}

exports.onServiceWorkerUpdateReady = () => window.location.reload(true)
